
import Back from "@/components/back"
import Button from "@/components/button"
import Footer from "@/components/footer"
import Input from "@/components/input"
import CategoryHead from "@/components/categoryHead"
import { vID } from "@/const/validate.const"
import useUpdateForm from "@/hooks/useUpdateForm"
import useValidation from "@/hooks/useValidation"
import React, { FC, useCallback, useState } from "react"
import styles from "./styles/setpwd.module.css"
import useAxios from "@/hooks/useAxios"
import useUser from "@/hooks/useUser"
import { EModalTypes, modalSubject$ } from "@/hooks/useModal"
// import Modal from "@/components/modal"
import useRoleAuth from "@/hooks/useRoleAuth"

const Setpwd: FC = () => {
    useRoleAuth()
    const [user] = useUser()
    const [formData, setFormData] = useState<any>({
        currentPwd: "",
        password: "",
        password2: ""
    })
    const { req } = useAxios("user/resetPassword", "post")
    const updateForm = useUpdateForm(setFormData);
    const [isValidSubmit, msgStatus] = useValidation(formData, {
        old_password: [vID.PWD_EMPTY],
        password: [vID.PWD_EMPTY, vID.MIN_TEXT_6_20],
        password2: [vID.PWD2_EMPTY, vID.MIN_TEXT_6_20, vID.PWD_PWD2_NOT_MATCH],
    }, "password");

    const submitHandler = useCallback(async () => {
        if (isValidSubmit) {
            const res = await req({ ...formData, token: user?.token })
                return modalSubject$.next({
                    id: EModalTypes.NORMAL,
                    innerTitle: res?.msg,
                    btn: true,
                })
        }
    }, [isValidSubmit, user, formData])

    return (
        <>
            <CategoryHead>
                <div className="plr mh700 mt80">
                    <Back>修改密码</Back>
                    <div className="mw1100">
                        <div>
                            <Input errMsg={msgStatus[0]} prefix={<div>当前密码</div>} value={formData.old_password} onChange={(e) => updateForm({ ...formData, "old_password": e })} line type="password" />
                            <Input errMsg={msgStatus[1]} prefix={<div>修改密码</div>} value={formData.password} onChange={(e) => updateForm({ ...formData, "password": e })} line type="password" />
                            <Input errMsg={msgStatus[2]} prefix={<div>确认密码</div>} value={formData.password2} onChange={(e) => updateForm({ ...formData, "password2": e })} line type="password" />
                            <div className={`${styles.btnpwd} mt20`}>
                                <Button event={submitHandler} cls="btn_sty2">确认修改</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </CategoryHead>
            {/* <Modal /> */}
        </>

    )
}
export default Setpwd;
