// import { vID, validFn, validMsg } from "@/const/validate.const";
import React, { FC, useCallback } from "react";
import styles from "@/components/styles/input.module.css"

interface IInput {
    placeholder?: string;
    line?: boolean;
    type?: string;
    prefix?: React.ReactElement;
    endFix?: React.ReactElement;
    onChange?: (e: any, valid?: boolean) => void;
    value?: string;
    errMsg?: any;
}

const Input: FC<IInput> = ({ placeholder, line, type, prefix, endFix, onChange, value, errMsg}) => {  

    // const onChangeHandler = useCallback((e: any) => {
        // if (Array.isArray(validateId) && validateId.length > 0) {
        //     const isNotValid = validateId.filter((x) => validFn[x] && !validFn[x](e, value2));
        //     onChange && onChange(e, isNotValid.length === 0)
        //     errEvent && errEvent(isNotValid.length === 0)
        //     setErrMsg(isNotValid.length > 0 ? validMsg(isNotValid[0]) : "")
        // }
        // return onChange && onChange(e)
    // }, [])

    const handleChange = (e: any) => {
        // debounceFn(e.target.value);
        return onChange && onChange(e.target.value)
    };

    // const debounceFn = useCallback(_.debounce(onChangeHandler, 30), []);

    const errmsgHandle = useCallback(() => {
        if (Array.isArray(errMsg)) {
            // 验证信息为同个输入框存在多个所以errmsg需要判断
            const _emsg = errMsg.filter((x) => x !== true);
            return _emsg[0] || ''
        }
        return errMsg || ''
    }, [errMsg])

    return (
        <>
            <div className={`fl align_center gap10 ${styles.inp}`}>
                {!!prefix && prefix}
                <input value={value} onChange={handleChange} type={type || 'text'} placeholder={placeholder} />
                {!!endFix && endFix}
                <div className={styles.err}>{errmsgHandle()}</div>
            </div>
            {line && <div className={styles.line}></div>}
        </>
    )
}
export default Input;