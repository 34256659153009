import { createBrowserRouter } from "react-router-dom";
import React from "react";
// import App from "./App";
import Main from "./pages/main/main";
import ChapterIndex from "./pages/s/chapter";
import DetailIndex from "./pages/s/detail";
import VideoId from "./pages/s/video";
import Setting from "./pages/user/setting";
import Setpwd from "./pages/user/setpwd";
import UserInfo from "./pages/user/info";
import Charge from "./pages/user/charge";
import ChargeTopic from "./pages/user/chargetopic";
import UserBuy from "./pages/user/buy";
import BindPhone from "./pages/user/bindphone";
import UserBill from "./pages/user/bill";
import UserMessage from "./pages/user/message";
import UserMessageDetail from "./pages/user/messagedetail";
import UserTopicBill from "./pages/user/topicBill";
import UserRedeem from "./pages/user/redeem";
import Register from "./pages/user/register";
import News from "./pages/user/news";
import External from "./pages/user/external";
import Enter from "./pages/user/enter";
import Forget from "./pages/user/forget";
import Help from "./pages/user/help";
import Login from "./pages/user/login";
import Thunder from "./pages/help/thunder/index"
import MainIndex from "./pages/main";
import Error from "./pages/user/Error";
import ParentalControl from "./pages/information/parentalControl";
import Xunlei from "./pages/help/xunlei";
// import createLazyComponent from "./components/Lazy";

// const LazyMainIndex = createLazyComponent(MainIndex)
const Fdm = React.lazy(() => import("./pages/help/fdm/index"))
const Terms = React.lazy(() => import("./pages/information/terms"))
const Privacy = React.lazy(() => import("./pages/information/privacy"))
const Dmca = React.lazy(() => import("./pages/information/dmca"))
const Page2257 = React.lazy(() => import("./pages/information/2257"))
const Dsa = React.lazy(() => import("./pages/information/dsa"))
const ContentRemoval = React.lazy(() => import("./pages/information/ContentRemoval"))
const Support = React.lazy(() => import("./pages/information/support"))
const TrustSafety = React.lazy(() => import("./pages/information/trustSafety"))

export const router = createBrowserRouter([
    {
        path: "*",
        element: <Error />,
    },
    {
        path: "/",
        element: <Enter />,
    },
    {
        path: "/t/:r",
        element: <Enter />,
    },
    {
        path: "/main",
        element: <MainIndex />,
    },
    {
        path: "/topic",
        element: <MainIndex />,
    },
    {
        path: "page/:channel/:id",
        element: <Main />
    },
    {
        path: "s/chapter/:channel/:cid/:id",
        element: <ChapterIndex />
    },
    {
        path: "s/detail/:channel/:cid/:id",
        element: <DetailIndex />
    },
    {
        path: "s/detail/:channel/:id",
        element: <DetailIndex />
    },
    {
        path: "s/video/:channel/:id",
        element: <VideoId />
    },
    {
        path: "user/bill",
        element: <UserBill />
    },
    {
        path: "user/message",
        element: <UserMessage />
    },
    {
        path: "user/messagedetail/:id",
        element: <UserMessageDetail />
    },
    {
        path: "user/topicBill",
        element: <UserTopicBill />
    },
    {
        path: "user/redeem",
        element: <UserRedeem />
    },
    {
        path: "user/bindphone",
        element: <BindPhone />
    },
    {
        path: "user/buy",
        element: <UserBuy />
    },
    {
        path: "user/charge",
        element: <Charge />
    },
    {
        path: "user/chargetopic",
        element: <ChargeTopic />
    },
    {
        path: "user/info",
        element: <UserInfo />
    },
    {
        path: "user/setpwd",
        element: <Setpwd />
    },
    {
        path: "user/setting",
        element: <Setting />
    },
    {
        path: "help",
        element: <Help />
    },
    {
        path: "forget",
        element: <Forget />
    },
    {
        path: "enter",
        
        element: <Enter />
    },
    {
        path: "external",
        element: <External />
    },
    // {
    //     path: "news",
    //     element: <News />
    // },
    {
        path: "register",
        element: <Register />
    },
    {
        path: "login",
        element: <Login />
    },
    {
        path: "page/help/thunder",
        element: <Thunder />
    },
    {
        path: "page/help/fdm",
        element: <Fdm />
    },
    {
        path: "page/help/xunlei",
        element: <Xunlei />
    },
    {
        path: "information/terms",
        element: <Terms />
    },
    {
        path: "information/privacy",
        element: <Privacy />
    },
    {
        path: "information/dmca",
        element: <Dmca />
    },
    {
        path: "information/2257",
        element: <Page2257 />
    },
    {
        path: "information/dsa",
        element: <Dsa />
    },
    {
        path: "information/content-removal",
        element: <ContentRemoval />
    },
    {
        path: "information/support",
        element: <Support />
    },
    {
        path: "information/trust-safety",
        element: <TrustSafety />
    },
    {
        path: "information/parental-control",
        element: <ParentalControl />
    },
]);