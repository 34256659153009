export enum vID {
    MIN_TEXT_6_12 = "MIN_TEXT_6_12",
    MIN_TEXT_6_20 = "MIN_TEXT_6_20",
    USERNAME_EMPTY = "USERNAME_EMPTY",
    PWD_EMPTY = "PWD_EMPTY",
    PWD2_EMPTY = "PWD2_EMPTY",
    PWD_PWD2_NOT_MATCH = "PWD_PWD2_NOT_MATCH",
    PHONE_EMPTY = 'PHONE_EMPTY',
    PHONE_AREA_CODE = "PHONE_AREA_CODE",
    PHONE_NUMBER = "PHONE_NUMBER",
    CHINA_PHONE_NUMBER = "CHINA_PHONE_NUMBER",
    TRUE_FORMAT_PHONE = "TRUE_FORMAT_PHONE",
    CODE = 'CODE',
    CODE_EMPTY = "CODE_EMPTY"
}

export const validMsg = (id: string) => {
    const map = {
        USERNAME_EMPTY: "请输入用户名",
        MIN_TEXT_6_12: "必须由6-12位的数字或者字母组成",
        MIN_TEXT_6_20: "密码必须由6-20位的数字或者字母组成",
        PWD_EMPTY: "请输入密码",
        PWD2_EMPTY: "请输入密码",
        PWD_PWD2_NOT_MATCH: "两次密码不匹配，请确认后重新输入",
        PHONE_EMPTY: '请输入手机号码',
        PHONE_AREA_CODE: '请选择正确的手机区号',
        PHONE_NUMBER: "请选择手机区号",
        CHINA_PHONE_NUMBER: "请填写正确的手机号码",
        TRUE_FORMAT_PHONE: "请填写正确的手机号码",
        CODE: "短信验证码必须是6位数字",
        CODE_EMPTY: "请输入兑换码"
    } as any
    return map[id] || 'a'
} 

export const validFn = {
    MIN_TEXT_6_12: (value: string) =>  /^[a-zA-Z0-9]{6,12}$/.test(value),
    MIN_TEXT_6_20: (value: string) =>  /^[a-zA-Z0-9]{6,20}$/.test(value),
    PWD_PWD2_NOT_MATCH: (val1: string, val2: string) => {
        console.log(val1, val2)
        return val1 === val2
    },
    USERNAME_EMPTY: (value: string) => !!value,
    PWD_EMPTY: (value: string) => !!value,  
    PWD2_EMPTY: (value: string) => !!value,  
    PHONE_EMPTY: (value: string) => !!value, 
    PHONE_AREA_CODE: (value: string) => {
        return /^(\+[0-9]+)+$/.test(`${value}`)
    },
    PHONE_NUMBER: (value: string) => `${value}`.length >= 5 && `${value}`.length <= 11,
    CHINA_PHONE_NUMBER: (value: string) => /^1[3456789]\d{9}$/.test(`${value}`),
    TRUE_FORMAT_PHONE: (value: string) => {
        return /^[0-9]+$/.test(`${value}`)
    },
    CODE: (value: string) => /^[0-9]{6}$/.test(`${value}`),
    CODE_EMPTY: (value: string) => !!value,  
} as any